import React, { memo } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigatorOnLine } from "components/NetworkStatus";
import { BasketProvider } from "contexts/BasketContext";

import {
  getLocalStorageItem,
  isIdentical,
  isOrderStatusActive,
  joinParams,
  setLocalStorageItem,
  translateOrderStatus,
  translateOrderType,
} from "data/functions";

import { getUA } from "data/functions";
import { isObjectEmpty } from "data/functions";
import { actions } from "data/globals";
import { saveAuthData } from "data/mobapp_functions";
import { promotions } from "data/models";
import { getActions } from "tools/requests";

export const DataContext = React.createContext();

function clear(state) {
  let obj = {};
  if (!isObjectEmpty(state.dataObj?.deviceWidth))
    obj["deviceWidth"] = state.dataObj?.deviceWidth;
  let keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.includes("data_")) {
      keys = [...keys, key];
    }
  }
  keys.forEach((el) => {
    localStorage.removeItem(el);
  });

  return obj;
}
export const reducer = (state, action) => {
  switch (action.type) {
    case actions.clearArray: {
      let obj = clear(state);
      if (!isIdentical(state?.dataObj, obj)) return { ...state, dataObj: obj };
      else return state;
    }
    case actions.pushArray: {
      let stateValue = state?.dataObj[action.paramName];
      let newValue = joinParams(stateValue, action.paramValue, action.defType);

      if (!isIdentical(stateValue, newValue)) {
        setLocalStorageItem("data_" + action.paramName, newValue);
        return {
          ...state,
          dataObj: {
            ...state.dataObj,
            [action.paramName]: newValue,
          },
        };
      } else return state;
    }
    case actions.resetCache: {
      let obj = clear(state);
      if (!isIdentical(state?.dataObj, obj)) return { ...state, dataObj: obj };
      else return state;
    }
    case actions.setArray: {
      let paramValue = state?.dataObj[action.paramName];

      if (!isIdentical(paramValue, action.paramValue)) {
        setLocalStorageItem("data_" + action.paramName, action.paramValue);
        return {
          ...state,
          dataObj: {
            ...state?.dataObj,
            [action.paramName]: action.paramValue,
          },
        };
      } else return state;
    }
    case actions.forceSet: {
      if (!action.paramName) return state;

      try {
        setLocalStorageItem("data_" + action.paramName, action.paramValue);
        return {
          ...state,
          dataObj: {
            ...state?.dataObj,
            [action.paramName]: action.paramValue,
          },
        };
      } catch (e) {
        return state;
      }
    }
    default:
      return state;
  }
};

export const initialState = {
  dataObj: {},
};

export function CheckSize(theme) {
  return useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
}

function DataProvider({ children }) {
  const theme = useTheme();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const isOnline = useNavigatorOnLine();
  let greaterThanMid = useMediaQuery(theme.breakpoints.up("md"), {
    noSsr: true,
  });
  let greaterThanLg = useMediaQuery(theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const value = {
    clearCache: React.useCallback(
      () =>
        dispatch({
          type: actions.resetCache,
        }),
      [],
    ),
    clearDataArr: React.useCallback(
      () =>
        dispatch({
          type: actions.clearArray,
        }),
      [],
    ),
    dataObj: state.dataObj,
    forceSetDataObj: React.useCallback(
      (value, param) =>
        dispatch({
          paramName: param,
          paramValue: value,
          type: actions.forceSet,
        }),
      [],
    ),
    pushDataObj: React.useCallback(
      (value, param, defType = {}) =>
        dispatch({
          defType: defType,
          paramName: param,
          paramValue: value,
          type: actions.pushArray,
        }),
      [],
    ),
    setDataObj: React.useCallback(
      (value, param) =>
        dispatch({
          paramName: param,
          paramValue: value,
          type: actions.setArray,
        }),
      [],
    ),
  };

  window.setLocation = function (lat, lng) {
    dispatch({
      paramName: "locationParams",
      paramValue: { location_latitude: lat, location_longitude: lng },
      type: actions.setArray,
    });
    console.log("Location set");
  };

  window.addDevice = function (deviceToken) {
    dispatch({
      paramName: "deviceToken",
      paramValue: deviceToken,
      type: actions.setArray,
    });
    console.log("DeviceToken set");
  };

  window.getClientID = function () {
    return state?.dataObj?.clientID;
  };

  React.useEffect(() => {
    if (!isObjectEmpty(state?.dataObj?.clientID))
      saveAuthData(state?.dataObj?.clientID);
    // console.log(state.dataObj);
  }, [state?.dataObj?.clientID]);

  // React.useEffect(() => {
  //   if (isObjectEmpty(state?.dataObj?.promotions) && !isObjectEmpty(promotions))
  //     dispatch({
  //       paramName: "promotions",
  //       paramValue: promotions,
  //       type: actions.setArray,
  //     });
  //   // console.log(state.dataObj);
  // }, [state?.dataObj]);

  async function getPromo() {
    let promos = await getActions();
    if (!isObjectEmpty(promos))
      if (!isIdentical(state?.dataObj?.promotions, promos))
        dispatch({
          paramName: "promotions",
          paramValue: promos,
          type: actions.setArray,
        });
  }

  React.useEffect(() => {
    getPromo();
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = undefined;
      try {
        value = getLocalStorageItem(key);
        if (!isObjectEmpty(value) && key.includes("data_")) {
          dispatch({
            paramName: key.replace("data_", ""),
            paramValue: value,
            type: actions.setArray,
          });
        }
      } catch {}
    }
  }, []);

  React.useEffect(() => {
    let mobile = greaterThanMid ? false : true;
    let pad = !greaterThanLg && greaterThanMid ? true : false;
    if (
      !isIdentical(mobile, state?.dataObj?.deviceWidth?.isMobile) ||
      !isIdentical(pad, state?.dataObj?.deviceWidth?.isPad)
    )
      dispatch({
        defType: {},
        paramName: "deviceWidth",
        paramValue: { isMobile: mobile, isPad: pad },
        type: actions.pushArray,
      });
  }, [greaterThanMid, greaterThanLg, state?.dataObj]);

  React.useEffect(() => {
    if (isOnline !== state?.dataObj?.isOnline)
      dispatch({
        paramName: "isOnline",
        paramValue: isOnline,
        type: actions.setArray,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  React.useEffect(() => {
    dispatch({
      paramName: "platform",
      paramValue: getUA(),
      type: actions.setArray,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.userAgent]);

  React.useEffect(() => {
    if (!isObjectEmpty(state.dataObj?.orders)) {
      let statusArray = [];
      let typeArray = [];
      state.dataObj?.orders.forEach((order) => {
        if (!isObjectEmpty(order?.status)) {
          if (!statusArray.includes(translateOrderStatus(order?.status)))
            statusArray = [...statusArray, translateOrderStatus(order?.status)];
          if (!typeArray.includes(translateOrderType(order?.type)))
            typeArray = [...typeArray, translateOrderType(order?.type)];
        }
      });
      if (!isObjectEmpty(statusArray))
        dispatch({
          paramName: "orderStatuses",
          paramValue: statusArray,
          type: actions.setArray,
        });
      if (!isObjectEmpty(typeArray))
        dispatch({
          paramName: "orderTypes",
          paramValue: typeArray,
          type: actions.setArray,
        });
    }
  }, [state.dataObj?.orders]);

  React.useEffect(() => {
    if (
      !isObjectEmpty(state?.dataObj?.carArr) &&
      !isObjectEmpty(state?.dataObj?.recsArray)
    ) {
      let car = getCarWithRecs(
        state?.dataObj?.carArr,
        state?.dataObj?.recsArray,
      );
      if (!isObjectEmpty(car) && !isIdentical(state?.dataObj?.usedCar, car)) {
        dispatch({
          paramName: "usedCar",
          paramValue: car,
          type: actions.setArray,
        });
      }
    }
  }, [state?.dataObj?.recsArray]);

  function getCarWithRecs(carArr, recsArray) {
    let car = {};
    if (isObjectEmpty(carArr) || isObjectEmpty(recsArray)) return car;

    for (let index = 0; index < carArr.length; index++) {
      const element = carArr[index];
      if (!isObjectEmpty(recsArray[element?.car?.id])) {
        return element;
      }
    }

    return car;
  }

  React.useEffect(() => {
    let arr = state.dataObj?.orders;
    let count = 0;
    (arr ?? []).forEach((order) => {
      if (isOrderStatusActive(order.status)) count = count + 1;
    });
    if (count !== state.dataObj?.activeOrdersCount)
      dispatch({
        paramName: "activeOrdersCount",
        paramValue: count,
        type: actions.setArray,
      });

    // eslint-disable-next-line
  }, [state?.dataObj?.orders]);

  function pushDataObj(value, param, defType = {}) {
    dispatch({
      defType: defType,
      paramName: param,
      paramValue: value,
      type: actions.pushArray,
    });
  }
  return (
    <DataContext.Provider value={value}>
      <BasketProvider>{children}</BasketProvider>
    </DataContext.Provider>
  );
}
export const GetData = () => {
  const context = React.useContext(DataContext);
  return context;
};

export { DataProvider };

import { FolderOutlined } from "@mui/icons-material";
import { Box, Chip, LinearProgress, Paper } from "@mui/material";
import CustomizedBreadcrumbs from "components/Breadcrumbs";
import { NotFoundBlock } from "components/NotFoundBlock";
import { GetCategoriesList } from "components/gql/queris/GetCategoriesList";
import { GetCategoryElements } from "components/gql/queris/GetCategoryElements";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import {
  getBreadcrumbs,
  getCategories,
  getImageUrlByName,
  isIdentical,
  isObjectEmpty,
  recursiveSearchCat,
} from "data/functions";
import { FolderBlock } from "pages/services/FolderBlock";
import Services from "pages/services/Services";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const FolderPage = (props) => {
  const { dataObj, pushDataObj } = GetData();
  const [item, setItem] = useState(
    window.history.state
      ? (dataObj?.serviceList ?? [])?.find(
          (el) => el?.id === window.history.state?.folder,
        ) || {}
      : {},
  );
  const [br, setBr] = useState([]);
  const [visible, setVisible] = React.useState();
  const { cInfo } = GetCInfo();
  const [catRefetch, catVariables] = GetCategoriesList();
  const [elRefetch, elVariables] = GetCategoryElements();

  useEffect(() => {
    window.history.replaceState(
      { folder: item?.id || "" },
      "",
      window.location.pathname,
    );

    if (cInfo?.UUID) {
      getCategories(
        catRefetch,
        elRefetch,
        item,
        { dataObj, pushDataObj },
        cInfo?.UUID,
      );

      let b = getBreadcrumbs(dataObj?.serviceList, item?.id || "", "id");
      setBr(b);
    }

    // eslint-disable-next-line
  }, [item]);

  return (
    <React.Fragment>
      <Box sx={{ padding: "0.4rem" }}>
        <Paper sx={{ position: "sticky", top: 0, zIndex: 3 }}>
          <CustomizedBreadcrumbs array={br} func={setItem} />
        </Paper>
        <Box sx={{ height: "2px" }}>
          {((catVariables.loading && isObjectEmpty(item?.children)) ||
            (elVariables.loading && isObjectEmpty(item?.elements))) && (
            <LinearProgress sx={{ height: "2px" }} />
          )}
        </Box>
        {isObjectEmpty(
          (!isObjectEmpty(dataObj?.serviceList)
            ? dataObj?.serviceList
            : []
          ).filter((el) => (item?.id || "") === el?.parent_id),
        ) &&
          !catVariables.loading &&
          !elVariables.loading && <NotFoundBlock />}
        <Box
          sx={{
            padding: "0.4rem",
            gap: "12px",
            display: "flex",
            flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
            flexWrap: "wrap",
            // justifyContent: "space-evenly",
            width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
          }}
        >
          {[
            ...(!isObjectEmpty(dataObj?.serviceList)
              ? dataObj?.serviceList
              : []),
          ]
            .sort((a, b) => (a.folder === true ? 1 : -1))
            .map((element, ind) => {
              if (
                (item?.id || "") === element?.parent_id &&
                element.folder === true
              ) {
                return (
                  <Box
                    key={ind}
                    onClick={() => setItem(element)}
                    sx={{
                      width: dataObj?.deviceWidth?.isMobile
                        ? "100%"
                        : dataObj?.deviceWidth?.isPad
                          ? "49%"
                          : "32%",
                    }}
                  >
                    <FolderBlock
                      chip={<Chip label="Папка" color="secondary" />}
                      iconElement={
                        <FolderOutlined
                          sx={{
                            marginLeft: "-6px",
                            marginRight: "6px",
                            width: "0.86em",
                            height: "0.86em",
                          }}
                        />
                      }
                      image={getImageUrlByName(element.name)}
                      // item={el}
                      // setItem={setItem}
                      title={element.name}
                    />
                  </Box>
                );
              } else return "";
            })}
        </Box>
        <Services
          checked={props.checked}
          handleChecked={props.handleChecked}
          parent={item}
        />
      </Box>
    </React.Fragment>
  );
};

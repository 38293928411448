import { HeaderRow } from "components/HeaderRow";
import { MapBlock } from "components/MapBlock";
import { PromotionSlider } from "components/sliders/PromotionSlider";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import { PromoList } from "pages/promotions/list/PromoList";
import React from "react";

export function MainFooter() {
  const { dataObj } = GetData();
  const { cInfo } = GetCInfo();

  return (
    <React.Fragment>
      {!isObjectEmpty(cInfo?.departments) && <MapBlock />}
      {
        // dataObj?.includeBeta &&
        dataObj?.deviceWidth?.isMobile ? (
          <>
            <HeaderRow
              boxShadow="none"
              navigateOnClick="/promotions"
              text="Акции"
            />
            <PromoList fromMain={true} />
          </>
        ) : (
          <>
            <HeaderRow
              boxShadow="none"
              navigateOnClick="/promotions"
              text="Акции"
            />
            <PromotionSlider fromMain={true} />
          </>
        )
      }
    </React.Fragment>
  );
}

import React from "react";

import styled from "@emotion/styled";
import { Delete } from "@mui/icons-material";
import { IconButton, TableCell as MuiTableCell, TableRow } from "@mui/material";
import { GetBasket } from "contexts/BasketContext";
import { CheckSize, GetData } from "contexts/DataContext";
import { hex2rgb } from "data/functions";

const TableCell = styled(MuiTableCell)`
  // font-size: ${(props) => (CheckSize(props?.theme) ? "1rem" : "0.875rem")};
  font-size: 0.875rem;
  padding: 0px 16px;
  border-bottom: ${(props) =>
    props?.urgency
      ? props?.urgency === 2
        ? "1px solid " + hex2rgb("#ff0000", 0.5)
        : "1px solid " +
          hex2rgb(props?.custom?.telegramColors?.button_color, 0.5)
      : undefined};
`;

export function TableItemRow(props) {
  const { dataObj } = GetData();
  const { getPrice, prices, sectionItemDelete, sections } = GetBasket();
  const [price, setPrice] = React.useState(props?.element?.salePrice || 0);
  const [priceType, setPriceType] = React.useState("");

  React.useEffect(() => {
    if (props?.section === sections?.services) {
      let carID = dataObj?.usedCar?.car?.id || "all";
      let priceObj = getPrice(props?.element?.id, carID);
      setPrice(priceObj?.salePrice || 0);
      setPriceType(priceObj?.priceType || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.usedCar, prices, props?.element]);

  return (
    <TableRow sx={{ background: "#4e4e4e0a" }}>
      <TableCell urgency={props?.element?.urgency}>
        {props?.element?.name || props?.element?.text}
      </TableCell>

      {props?.size !== "small" && (
        <TableCell
          align="right"
          sx={{ padding: "0 !important", minWidth: "80px" }}
          urgency={props?.element?.urgency}
        >
          {price
            ? priceType === "min_price"
              ? "от " + price + " ₽"
              : price + " ₽"
            : ""}
        </TableCell>
      )}
      <TableCell
        align="center"
        sx={{ padding: "2px !important" }}
        urgency={props?.element?.urgency}
        width={20}
      >
        <IconButton
          edge="end"
          onClick={() => {
            sectionItemDelete("registration", props?.element, props?.section);
          }}
          sx={{ margin: 0, padding: 2 }}
        >
          <Delete sx={{ width: "0.875em", height: "0.875em" }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

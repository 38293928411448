import { useMutation } from "@apollo/client";
import { DECODE_VIN } from "components/gql/gql_queries";
import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty, isObjectEmpty } from "data/functions";

export function DecodeVinMutation() {
  const { setAlert } = GetAlertData();
  const [mutation, variables] = useMutation(DECODE_VIN, {
    onCompleted: (data) => {
      if (data?.exch_decodeVin?.code === 200 && data?.exch_decodeVin?.data) {
        consoleBeauty("Успешная расшифровка VIN", "GraphQL", "darkgreen");
      } else if (
        data?.exch_decodeVin?.code === 200 &&
        isObjectEmpty(data?.exch_decodeVin?.data)
      ) {
        setAlert("Данные по VIN не найдены", "warning");
      } else {
        // setAlert("Не удалось добавить автомобиль", "warning");
        consoleBeauty(
          "DecodeVin: " + data?.exch_decodeVin?.message,
          "GraphQL",
          "red",
        );
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          "DecodeVin: " + data?.exch_decodeVin?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "DecodeVin: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}

import { useLazyQuery } from "@apollo/client";
import { FLOW_INIT } from "components/gql/gql_queries";
import { consoleBeauty } from "data/functions";

export function ExtFlowInitQuery() {
  const [refetch, variables] = useLazyQuery(FLOW_INIT, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
    onCompleted: () => {
      consoleBeauty("Запрос авторизации. Успех.", "GraphQL", "darkgreen");
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "ExtFlowInit: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
// import JsonView from "@uiw/react-json-view";
import { darkTheme } from "@uiw/react-json-view/dark";
import { lightTheme } from "@uiw/react-json-view/light";
import { GetBasket } from "contexts/BasketContext";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { getLocalStorageItem, isObjectEmpty } from "data/functions";
import { BoxFlexRow } from "data/styleGlobals";
import React from "react";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

export const StorageDialog = (props) => {
  const { dataObj, setDataObj } = GetData();
  const { cInfo } = GetCInfo();
  const { tokenData } = GetAuthData();
  const basketContext = GetBasket();
  const theme = useTheme();
  const [array, setArray] = React.useState({});
  React.useEffect(() => {
    let arr = {};
    if (!isObjectEmpty(dataObj)) arr = { ...arr, ...{ dataObj: dataObj } };
    if (!isObjectEmpty(theme)) arr = { ...arr, ...{ theme: theme } };
    if (!isObjectEmpty(cInfo)) arr = { ...arr, ...{ cInfo: cInfo } };
    if (!isObjectEmpty(basketContext?.basket))
      arr = { ...arr, ...{ basketData: basketContext?.basket } };
    if (!isObjectEmpty(basketContext?.prices))
      arr = { ...arr, ...{ pricesData: basketContext?.prices } };
    if (!isObjectEmpty(tokenData))
      arr = { ...arr, ...{ tokenData: tokenData } };

    if (!isObjectEmpty(getLocalStorageItem("tokenDataOriginal")))
      arr = {
        ...arr,
        ...{ tokenDataOriginal: getLocalStorageItem("tokenDataOriginal") },
      };

    if (!isObjectEmpty(localStorage))
      arr = {
        ...arr,
        ...{ localStorage: JSON.parse(JSON.stringify(localStorage)) },
      };
    if (!isObjectEmpty(sessionStorage))
      arr = {
        ...arr,
        ...{ sessionStorage: JSON.parse(JSON.stringify(sessionStorage)) },
      };
    setArray(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataObj,
    cInfo,
    tokenData,
    theme,
    basketContext,
    getLocalStorageItem("tokenDataOriginal"),
  ]);

  return (
    <Dialog
      PaperProps={{
        style: {
          width: "90vw",
          maxWidth: "90vw",
          minHeight: "90vh",
        },
      }}
      onClose={() => props?.setOpen(false)}
      open={props.status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => props?.setOpen(false)}
      >
        Параметры
      </DialogTitle>
      <DialogContent sx={{ padding: 0, overflowY: "scroll" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={dataObj?.includeBeta || false}
              onChange={(e) => setDataObj(e?.target?.checked, "includeBeta")}
            />
          }
          label="Включить экспериментальные функции"
          sx={{ margin: "0rem 1rem" }}
        />
        {Object.keys(array).map((el, index) => {
          if (!isObjectEmpty(el)) {
            return (
              <Box key={index}>
                <BoxFlexRow
                  key={index}
                  sx={{
                    padding: "0rem 1rem",
                    flexDirection: "column!important",
                    alignItems: "start!important",
                  }}
                >
                  <Typography
                    variant={
                      dataObj?.deviceWidth?.isMobile ? "caption" : "body1"
                    }
                  >
                    {el}
                  </Typography>

                  {/* <JsonView
                    collapsed
                    displayDataTypes={false}
                    style={
                      theme?.palette?.mode === "dark" ? darkTheme : lightTheme
                    }
                    value={
                      typeof array[el] === "object"
                        ? array[el]
                        : { [typeof array[el]]: array[el] }
                    }
                  /> */}
                  <JsonView
                    collapseStringsAfterLength={20}
                    collapsed={true}
                    dark={theme?.palette?.mode === "dark" ? true : false}
                    enableClipboard={true}
                    src={array[el]}
                    style={{ fontSize: "small" }}
                    theme="atom"
                  />
                </BoxFlexRow>
                <Divider />
              </Box>
            );
          } else {
            return "";
          }
        })}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          fullWidth
          onClick={(e) => {
            props.setOpen(false);
          }}
          variant="outlined"
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

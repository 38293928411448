import { useLazyQuery } from "@apollo/client";
import { GET_MARK } from "components/gql/gql_queries";

import { consoleBeauty } from "data/functions";

export function GetMarkQuery() {
  const [refetch, variables] = useLazyQuery(GET_MARK, {
    errorPolicy: "all",
    // fetchPolicy: "no-cache",
    onCompleted: (data) => {
      consoleBeauty("Получена информация о марке", "GraphQL", "darkgreen");
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "Mark: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [refetch, variables];
}

import React, { useState } from "react";

import { Box, List, Typography } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetData } from "contexts/DataContext";
import { isIdentical } from "data/functions";
import { isObjectEmpty } from "data/functions";
import { Outlet, useNavigate } from "react-router-dom";

export default function PromoMain(props) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title={"Акции"} />
        <Box sx={{ overflow: "auto" }}>
          <PromoList />
          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  );
}

export function PromoList(props) {
  const { dataObj } = GetData();
  const [promoArr, setPromoArr] = useState(dataObj?.promotions || []);
  React.useEffect(() => {
    if (!isIdentical(promoArr, dataObj?.promotions))
      setPromoArr(dataObj?.promotions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataObj?.promotions]);

  const navigate = useNavigate();
  return (
    <List
      sx={{
        padding: "0 0.4rem",
        display: dataObj?.deviceWidth?.isMobile ? "block" : "flex",
        flexWrap: dataObj?.deviceWidth?.isMobile ? undefined : "wrap",
        gap: dataObj?.deviceWidth?.isMobile ? undefined : "10px",
        justifyContent: dataObj?.deviceWidth?.isMobile ? undefined : "center",
      }}
    >
      {!isObjectEmpty(promoArr) &&
        promoArr.map((el, index) => {
          if (props?.fromMain ? el?.showOnMain : true) {
            return <PromoBox el={el} ind={index} key={index} />;
          } else return "";
        })}
    </List>
  );
}

export function PromoBox(props) {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  return (
    <Box
      key={props?.ind}
      onClick={(e) =>
        navigate(`/promotions/${props?.el?.id}`, {
          state: { id: props?.el?.id },
        })
      }
      sx={{
        margin: dataObj?.deviceWidth?.isMobile ? "0.4rem 0" : undefined,

        // height: props?.slider
        //   ? undefined
        //   : dataObj?.deviceWidth?.isMobile
        //     ? "33vw"
        //     : dataObj?.deviceWidth?.isPad
        //       ? "25vw"
        //       : "12vw",
        // width:
        //   props?.slider || dataObj?.deviceWidth?.isMobile
        //     ? "100%"
        //     : dataObj?.deviceWidth?.isPad
        //       ? "48%"
        //       : "32%",
        width: "100%",
        height: "auto",
        backgroundImage: !isObjectEmpty(props?.el?.preview)
          ? `url(${props?.el?.preview})`
          : !isObjectEmpty(props?.el?.main)
            ? `url(${props?.el?.main})`
            : "",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        minWidth: 0,
        borderRadius: "4px",
        maxWidth: dataObj?.deviceWidth?.isMobile ? "undefined" : "450px",
        minHeight: dataObj?.deviceWidth?.isMobile ? "130px" : "160px",
        padding: "12px",
        boxSizing: "border-box",
        overflow: "hidden",
        color: "black",
        position: "relative",
        containerType: "inline-size",
      }}
    >
      <Box
        sx={{ maxWidth: "60%", position: "absolute", top: "10%", left: "5%" }}
      >
        <Typography
          sx={{
            whiteSpace: "break-spaces",

            fontWeight: "700",
            lineHeight: "1.2",
          }}
          variant="body1"
        >
          {props?.el?.title}
        </Typography>
        <Typography
          sx={{
            whiteSpace: "break-spaces",
            lineHeight: "1",
            maxWidth: "60%",
          }}
          variant="caption"
        >
          {props?.el?.text}
        </Typography>
      </Box>
    </Box>
  );
}

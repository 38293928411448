import { useMutation } from "@apollo/client";
import { EXT_REFRESH_TOKEN } from "components/gql/gql_queries";
import { consoleBeauty, setLocalStorageItem } from "data/functions";

export function ExtRefreshTokenMutation() {
  const [mutation, variables] = useMutation(EXT_REFRESH_TOKEN, {
    onCompleted: (data) => {
      if (
        data?.auth_extRefreshToken.code === 200 &&
        data?.auth_extRefreshToken?.data?.accessToken
      ) {
        // consoleBeauty(
        //   "Успешное обновление токена\n" +
        //     "Новый токен: " +
        //     data?.auth_extRefreshToken?.data?.accessToken,
        //   "GraphQL",
        //   "darkgreen",
        // );
      } else {
        consoleBeauty(
          "ExtRefresh: " + data?.auth_extRefreshToken?.message,
          "GraphQL",
          "red",
        );
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          "ExtRefresh: " + data?.auth_extRefreshToken?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "ExtRefreshToken: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return [mutation, variables];
}

import React from "react";
export const DialogContext = React.createContext();

function DialogProvider({ children }) {
  // const [dataObj, setDataObj] = React.useState([]);
  // const value = {
  //   dataObj, // <------ Expose Value to Consumer
  //   setDataObj, // <------ Expose Setter to Consumer
  // };

  const [dialogBody, setDialogBody] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(true);

  const setDialog = React.useCallback((open, component) => {
    setDialogOpen(() => open);
    setDialogBody(() => component);
  }, []);

  const contextValue = React.useMemo(
    () => ({
      setDialog,
    }),
    // eslint-disable-next-line
    [setDialog],
  );

  // React.useEffect(() => {
  //   let mobileParam = getLocalStorageItem("data_deviceWidth");
  //   if (
  //     !isObjectEmpty(mobileParam) &&
  //     !isIdentical(mobileParam?.isMobile, isMobile)
  //   ) {
  //     setIsMobile(mobileParam?.isMobile);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getLocalStorageItem("data_deviceWidth")]);

  return (
    <DialogContext.Provider value={contextValue}>
      {dialogBody &&
        React.cloneElement(dialogBody, {
          open: dialogOpen,
          setOpen: setDialogOpen,
        })}

      {children}
    </DialogContext.Provider>
  );
}
export const GetDialog = () => {
  const context = React.useContext(DialogContext);
  return context;
};

export { DialogProvider };

import { useLazyQuery } from "@apollo/client";
import { GET_SELF_DATA } from "components/gql/gql_queries";

import { GetData } from "contexts/DataContext";
import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty, isObjectEmpty } from "data/functions";

export function SelfDataQuery() {
  const { setLogined, token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(GET_SELF_DATA, {
    // errorPolicy: "all",
    fetchPolicy: "no-cache",
    skip: !token,
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "SelfData: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
      // setLogined(false);
    },
  });
  return [refetch, variables];
}

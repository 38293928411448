import { Box } from "@mui/material";
import Sidebar from "components/sidebar/Sidebar";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { GetDialog } from "contexts/DialogContext";
import { GetAuthData } from "contexts/TAuthContext";
import { isObjectEmpty } from "data/functions";
// import { setCookie } from "data/functions";
import navItems from "data/menuStructure";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeProfile } from "./profile/change/UserChangeDialog";

function Home() {
  const { setDialog } = GetDialog();
  const [open, setOpen] = React.useState(false);
  const { cInfo } = GetCInfo();
  if (!window.location.hash && window.addEventListener) {
    window.addEventListener("load", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
    window.addEventListener("orientationchange", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
    window.addEventListener("touchstart", function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 0);
    });
  }
  const { dataObj } = GetData();
  const [dashboardItems, setDashboardItems] = React.useState(
    navItems(dataObj) || null,
  );
  const { isLogined, token } = GetAuthData();
  const location = useLocation();
  const navigate = useNavigate();

  const spring = {
    damping: 20,
    mass: 2,
    stiffness: 150,
    type: "spring",
  };
  useEffect(() => {
    // console.log(token);
    if (isLogined === false) navigate("auth");
    else if (isLogined === true && location.pathname === "auth") navigate("/");
    // eslint-disable-next-line
  }, [isLogined, token]);

  useEffect(() => {
    // console.log(token);
    if (
      (!isObjectEmpty(cInfo?.regions) &&
        cInfo?.regions?.length > 1 &&
        isObjectEmpty(cInfo?.preferedRegion)) ||
      (dataObj.clientInfoArray && !dataObj.clientName)
    ) {
      setDialog(true, <ChangeProfile />);
      // setOpen(true);
    }

    if (dataObj?.carsCount === 0)
      navigate("cars/add", {
        state: { fromMain: true },
      });
    // eslint-disable-next-line
  }, [cInfo.regions, dataObj?.carsCount]);

  // useEffect(() => {
  //   setCookie();
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    setDashboardItems(navItems(dataObj));
    // eslint-disable-next-line
  }, [
    dataObj?.includeBeta,
    dataObj?.activeOrdersCount,
    dataObj?.unreadMessages,
    dataObj?.currentBasketCount,
  ]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%+" }}>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{
              style: {
                width: 256,
                position: "relative",
                height: "100svh",
              },
            }}
            items={dashboardItems}
          />
        </Box>
        <Box
          sx={{
            flexDirection: "column",
            width: { xs: "100%", md: "calc(100% - 256px)" },
            overflow: "auto",
          }}
        >
          {(isLogined || location.pathname === "/request") && (
            <AnimatePresence mode="wait">
              <motion.div
                animate={{ opacity: 1 }}
                key={location.pathname}
                style={{ height: "100svh", opacity: "0.5" }}
                transition={spring}
              >
                <Outlet usedCar={dataObj?.usedCar} />
              </motion.div>
            </AnimatePresence>
          )}
        </Box>
      </Box>
      {/* <ChangeProfile open={open} setOpen={setOpen} /> */}
    </React.Fragment>
  );
}

export default Home;

import { isObjectEmpty } from "data/functions";
import React from "react";
import FieldBlock from "./FieldBlock";

export default function FieldMileage(props) {
  function setMileage(e) {
    props?.setCarAddData((el) => {
      return { ...el, mileage: Number(e) };
    });
  }
  return (
    <FieldBlock
      // icon="км."
      iconMargin="0px"
      label="mileage"
      setValue={setMileage}
      title="Пробег, км"
      type="Number"
      value={
        isObjectEmpty(props?.carAddData?.mileage)
          ? 0
          : props?.carAddData?.mileage
      }
    />
  );
}

import { ExpandMore, MiscellaneousServices } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { GetData } from "contexts/DataContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef?.current?.offsetWidth || 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export const FolderBlock = (props) => {
  const { dataObj } = GetData();
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const { width } = useContainerDimensions(ref);

  return (
    <Box
      sx={{
        margin: "0.2rem",

        width: "100%",
        padding: props?.thereIsEl ? "2px 6px" : undefined,
        flex: "auto",
      }}
    >
      <Card
        sx={{
          position: "relative",
          borderLeft: props?.borderLeft ? props?.borderLeft : undefined,
          // border: props?.thereIsEl ? undefined : "1px solid black",
          display: props?.onBasket ? "flex" : undefined,
          alignItems: props?.onBasket ? "center" : undefined,
          flexDirection: props?.onBasket ? "row" : undefined,
        }}
      >
        <Box
          onClick={() => {
            if (props?.item) props?.setItem(props?.item);
          }}
        >
          {props?.image ? (
            <CardMedia
              component="img"
              height={props?.onBasket ? "60" : undefined}
              image={props?.image}
              ref={ref}
              sx={{
                width: props?.onBasket ? "60px" : undefined,
                maxHeight: props?.onBasket
                  ? undefined
                  : width
                    ? width / 3
                    : undefined,
              }}
              title={props?.title}
              width={props?.onBasket ? "60px" : undefined}
            />
          ) : null}
          {!props?.image && props?.onBasket ? (
            <MiscellaneousServices
              sx={{
                width: "60px",
                height: "60px",
                padding: "0.8rem",
                opacity: "0.2",
              }}
            />
          ) : null}
        </Box>
        <CardContent sx={{ padding: "4px 12px !important", width: "100%" }}>
          <Box
            onClick={() => {
              if (props?.item) props?.setItem(props?.item);
            }}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              fontWeight: props?.thereIsEl ? 400 : 500,
              fontSize: props?.thereIsEl ? undefined : "1.16rem",
              marginBottom: !props?.description ? 0 : undefined,
            }}
            variant="h6"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.iconElement}
              {props?.title}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {props?.price}
              {props?.deleteComponent}
            </Box>
          </Box>
          <Box
            onClick={() => {
              if (props?.item) props?.setItem(props?.item);
            }}
          >
            {props?.description && (
              <React.Fragment>
                <Box
                  // gutterBottom
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  {props?.description}
                </Box>
                {/* <Typography
                  sx={{
                    width: "90%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: expanded ? undefined : "1",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {props?.description}
                </Typography> */}
              </React.Fragment>
            )}
          </Box>
          {
            // (dataObj.includeBeta || props?.fullDescription) &&
            props?.thereIsEl && !props?.onBasket && (
              <Box sx={{ width: "100%", textAlign: "end" }}>
                <Button
                  onClick={(e) =>
                    navigate(`/services/${props?.item?.id}`, {
                      state: { serviceID: props?.item?.id },
                    })
                  }
                  sx={{
                    textTransform: "none",
                    padding: 0,
                  }}
                >
                  Подробнее
                </Button>
              </Box>
            )
          }
        </CardContent>
      </Card>
    </Box>
  );
};

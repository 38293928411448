import { useMutation } from "@apollo/client";

import { ADD_NEW_DEAL } from "components/gql/gql_queries";

import { GetAlertData } from "contexts/AlertContext";
import { consoleBeauty } from "data/functions";

export function NewDealMutation() {
  const { setAlert } = GetAlertData();

  const [mutation, variables] = useMutation(ADD_NEW_DEAL, {
    onCompleted: (data) => {
      if (data?.dealAdd?.code === 200) {
        consoleBeauty("Заявка создана", "GraphQL", "darkgreen");
        setAlert(
          "Заявка успешно создана!" + data?.dealAdd?.result?.dealNumber
            ? `Номер заявки: ${data?.dealAdd?.result?.dealNumber}`
            : "",
          "success",
        );
      } else {
        consoleBeauty("dealAdd: " + data?.dealAdd?.message, "GraphQL", "red");
        setAlert("Не удалось создать заявку", "warning");
        sessionStorage.setItem(
          "error_" + new Date(Date.now()).toISOString(),
          "dealAdd: " + data?.dealAdd?.message,
        );
      }
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "dealAdd: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
      setAlert("Не удалось создать заявку. \nПопробуйте позже.", "error");
    },
  });
  return [mutation, variables];
}

import React from "react";

import { Map } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { PageTitle } from "components/PageTitle";
import { GetCInfo } from "contexts/CompanyContext";
import { GetData } from "contexts/DataContext";
import { isObjectEmpty } from "data/functions";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { DepartmentBlock } from "pages/departments/list/DepartmentBlock";

export default function Departments() {
  const { dataObj } = GetData();
  const { cInfo, setCInfo } = GetCInfo();
  // const [openFilter, setOpenFilter] = React.useState(false);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <PageTitle title="Контакты" />
        {!isObjectEmpty(cInfo?.preferedRegion) &&
          !isObjectEmpty(cInfo?.regions) &&
          cInfo?.regions.length > 1 && (
            <Box>
              <PopupState popupId="popup-menu" variant="popover">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      color="inherit"
                      id="composition-button"
                      sx={{ textTransform: "none" }}
                      {...bindTrigger(popupState)}
                    >
                      <Map sx={{ margin: "2px" }} />
                      Регион: {cInfo?.preferedRegion?.name}
                    </Button>

                    <Menu {...bindMenu(popupState)}>
                      {cInfo?.regions?.map((reg, ind) => (
                        <MenuItem
                          key={ind}
                          onClick={() => {
                            // setOpenFilter(true);.

                            setCInfo(reg, "preferedRegion");
                            popupState.close();
                          }}
                        >
                          {reg?.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </Box>
          )}
        <Box sx={{ overflow: "auto" }}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: dataObj?.deviceWidth?.isMobile ? "column" : "row",
              flexWrap: "wrap",
              margin: 0,
            }}
          >
            {!isObjectEmpty(cInfo?.departments) &&
              cInfo?.departments.map((el, ind) => {
                if (
                  !isObjectEmpty(cInfo?.regions) &&
                  cInfo?.regions.length > 1 &&
                  !isObjectEmpty(cInfo?.preferedRegion)
                ) {
                  if (el?.region === cInfo?.preferedRegion?.name) {
                    return <DepBody el={el} ind={ind} />;
                  } else return "";
                } else {
                  return <DepBody el={el} ind={ind} />;
                }
              })}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function DepBody(props) {
  const { dataObj } = GetData();
  return (
    <Box
      key={props?.ind}
      sx={{
        width: dataObj?.deviceWidth?.isMobile ? "100%" : undefined,
        padding: "0.6rem",
      }}
    >
      <DepartmentBlock
        description={""}
        id={props?.el?.ID}
        locationLatitude={props?.el?.locationLatitude}
        locationLongitude={props?.el?.locationLongitude}
        phone={props?.el?.phone}
        reviews={[
          { name: "Иван", text: "Хороший сервис!", count: "4" },
          {
            name: "Ваня",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            count: "2",
          },
        ]}
        subheader={props?.el?.adress}
        title={props?.el?.name}
        workTime={props?.el?.workTime}
      />
    </Box>
  );
}

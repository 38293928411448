import { useLazyQuery } from "@apollo/client";
import { EXPORT_UPLOAD_URL } from "components/gql/gql_queries";

import { GetAuthData } from "contexts/TAuthContext";
import { consoleBeauty } from "data/functions";

export function ExportUploadUrlQuery() {
  const { token } = GetAuthData();
  const [refetch, variables] = useLazyQuery(EXPORT_UPLOAD_URL, {
    skip: !token,

    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "ExportUploadUrl: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
      // setLogined(false);
    },
  });
  return [refetch, variables];
}

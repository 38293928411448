import { useQuery } from "@apollo/client";
import { GET_MARKS } from "components/gql/gql_queries";
import { GetData } from "contexts/DataContext";

import { consoleBeauty, isIdentical, isObjectEmpty } from "data/functions";

export function GetMarksQuery() {
  const { dataObj, setDataObj } = GetData();
  const query = useQuery(GET_MARKS, {
    errorPolicy: "all",
    variables: {
      orderBy: "priority",
    },
    // fetchPolicy: "no-cache",
    onCompleted: (data) => {
      consoleBeauty("Получен список марок", "GraphQL", "darkgreen");
      onCompletedGetMarks(data, { dataObj, setDataObj });
    },
    onError: (e) => {
      sessionStorage.setItem(
        "error_" + new Date(Date.now()).toISOString(),
        "Marks: " + e.message,
      );

      consoleBeauty(e.message, "GraphQL", "red");
    },
  });
  return query;
}

export function onCompletedGetMarks(data, { dataObj, setDataObj }) {
  if (
    !isObjectEmpty(data?.exch_getMarks?.marks) &&
    !isIdentical(dataObj?.markList, data?.exch_getMarks?.marks)
  ) {
    setDataObj(data?.exch_getMarks?.marks, "markList");
  }
}

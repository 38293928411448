import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat,
  from,
} from "@apollo/client";
import { apolloConfig } from "config";
import React from "react";

import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";

import { getLocalToken } from "data/functions";

function ApolloContext({ children }) {
  const removeTypenameLink = removeTypenameFromVariables();

  const httpLink = new HttpLink({
    uri: apolloConfig.uri,
  });

  const authLink = new ApolloLink((operation, forward) => {
    let lStToken = getLocalToken();
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${lStToken ? lStToken : ""}`,
      },
    }));
    return forward(operation);
  });

  const link = from([removeTypenameLink, concat(authLink, httpLink)]);

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: link,
    name: "web",
    version: "1.0",
  });

  return (
    <ApolloProvider client={apolloClient} value={apolloClient}>
      {children}
    </ApolloProvider>
  );
}

export { ApolloContext };
